export default {
  // function
  setting: 'Click setting',
  edit: 'Edit',
  submitConfirm: 'Confirm submission (Cmd+S)',
  close: 'Close (Esc)',
  fullScreen: 'Full screen',
  cancelFullScreen: 'Cancel full screen',
  jsonIllegal: 'JSON illegal format',
  functionIllegal: 'Function illegal format',
  objectIllegal: 'Object illegal format',
  circularRef: 'The object with circular reference appears in the object',
  formatError: 'Format error',
  saved: 'saved',
  // expression
  valueIllegal:
    'The value type is an object type, which does not match the control type set by the current component property, please edit it in the property "Code Editing Mode"',
  jsExpression: 'Please enter a JS expression',
  // Mixin
  input: 'String Input',
  textarea: 'Multiline string Textarea',
  expression: 'Variable control Expression',
  monacoEditor: 'Monaco Editor',
  numberPicker: 'Number Picker',
  bool: 'Boolean Switch',
  datePicker: 'Date selection DatePicker',
  select: 'Dropdown selection Select',
  radio: 'Single choice RadioGroup',
  date: 'Date selection DatePicker',
  dateYear: 'Year selection DatePicker',
  dateMonth: 'Month selection DatePicker',
  dateRange: 'Date range selection DatePicker',
  list: 'Array List',
  object: 'Object ObjectButton',
  reactNode: 'Node type ReactNode',
  typeError: 'The configuration of the Minix component property Types is incorrect, there is an unsupported type [{type}], please check the component property configuration',
};

import { isI18nData } from './i18n';
function isPlainObject(value) {
  if (typeof value !== 'object') {
    return false;
  }
  var proto = Object.getPrototypeOf(value);
  return proto === Object.prototype || proto === null || Object.getPrototypeOf(proto) === null;
}
export function isTitleConfig(obj) {
  return isPlainObject(obj) && !isI18nData(obj);
}
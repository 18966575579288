import { common } from '@digiforce/dvd-engine';
import en_US from './en-US.json';
import vi_VN from './vi-VN.json';
const { editorCabin } = common;
const { createIntl } = editorCabin;

const { intl, intlNode, getLocale, setLocale } = createIntl({
  'en-US': en_US,
  'vi-VN': vi_VN,
});

export { intl, intlNode, getLocale, setLocale };

/**
 * just for legao
 * @author: jiushen
 */
// 需要通过 Env 来判断是否需要

import { some } from 'lodash';
var pseudoMap = ['hover', 'focus', 'active', 'visited'];
var RE_CAMEL = /[A-Z]/g;
var RE_HYPHEN = /[-\s]+(.)?/g;
var CSS_REG = /:root(.*)\{.*/i;
var PROPS_REG = /([^:]*):\s?(.*)/i;

// 给 css 分组
function groupingCss(css) {
  var stackLength = 0;
  var startIndex = 0;
  var group = [];
  css.split('').forEach(function (_char, index) {
    if (_char === '{') {
      stackLength++;
    }
    if (_char === '}') {
      if (stackLength === 1) {
        group.push(css.substring(startIndex, index + 1));
        startIndex = index + 1;
      }
      stackLength--;
    }
  });
  return group;
}
function isString(str) {
  return {}.toString.call(str) === '[object String]';
}
function hyphenate(str) {
  return str.replace(RE_CAMEL, function (w) {
    return "-" + w;
  }).toLowerCase();
}
function camelize(str) {
  return str.replace(RE_HYPHEN, function (m, w) {
    return w ? w.toUpperCase() : '';
  });
}
/**
 * convert
 * {background-color: "red"}
 * to
 * background-color: red;
 */
function runtimeToCss(runtime) {
  var css = [];
  Object.keys(runtime).forEach(function (key) {
    css.push("  " + key + ": " + runtime[key] + ";");
  });
  return css.join('\n');
}
function toNativeStyle(runtime) {
  if (!runtime) {
    return {};
  }
  if (runtime["default"]) {
    var _normalized = {};
    Object.keys(runtime).forEach(function (pseudo) {
      if (pseudo === 'extra') {
        _normalized[pseudo] = runtime[pseudo];
        return;
      }
      _normalized[pseudo] = toNativeStyle(runtime[pseudo]);
    });
    return _normalized;
  }
  var normalized = {};
  Object.keys(runtime).forEach(function (key) {
    normalized[camelize(key)] = runtime[key];
  });
  return normalized;
}
function normalizeStyle(style) {
  if (!style) {
    return {};
  }
  if (style["default"]) {
    var _normalized2 = {};
    Object.keys(style).forEach(function (pseudo) {
      if (pseudo === 'extra') {
        _normalized2[pseudo] = style[pseudo];
        return;
      }
      _normalized2[pseudo] = normalizeStyle(style[pseudo]);
    });
    return _normalized2;
  }
  var normalized = {};
  Object.keys(style).forEach(function (key) {
    normalized[hyphenate(key)] = style[key];
  });
  return normalized;
}
function toCss(runtime) {
  if (!runtime) {
    return ":root {\n\n}";
  }
  if (runtime["default"]) {
    var css = [];
    Object.keys(runtime).forEach(function (pseudo) {
      if (pseudo === 'extra') {
        Array.isArray(runtime.extra) && css.push(runtime.extra.join('\n'));
        return;
      }
      // 只需要对这四种做兼容
      var prefix = pseudoMap.indexOf(pseudo) > -1 ? ':' : '';
      css.push(":root" + (pseudo === 'default' ? '' : "" + prefix + pseudo) + " {\n" + runtimeToCss(normalizeStyle(runtime[pseudo])) + "\n}\n");
    });
    return css.join('\n');
  }
  return ":root {\n" + runtimeToCss(normalizeStyle(runtime)) + "\n}\n";
}
function cssToRuntime(css) {
  if (!css) {
    return {};
  }
  var runtime = {};
  var groups = groupingCss(css);
  groups.forEach(function (cssItem) {
    if (!cssItem.startsWith(':root')) {
      runtime.extra = runtime.extra || [];
      runtime.extra.push(cssItem.trim());
    } else {
      var res = /:root:?(.*)?{(.*)/ig.exec(cssItem.replace(/[\r\n]+/ig, '').trim());
      if (res) {
        var pseudo;
        if (res[1] && res[1].trim() && some(pseudoMap, function (pse) {
          return res[1].indexOf(pse) === 0;
        })) {
          pseudo = res[1].trim();
        } else if (res[1] && res[1].trim()) {
          pseudo = res[1];
        }
        var s = {};
        res[2].split(';').reduce(function (prev, next) {
          if (next.indexOf('base64') > -1) {
            prev[prev.length - 1] += ";" + next;
          } else {
            prev.push(next);
          }
          return prev;
        }, []).forEach(function (item) {
          if (item) {
            if (PROPS_REG.test(item)) {
              var props = item.match(PROPS_REG);
              var key = props[1];
              var value = props[2];
              if (key && value) {
                s[key.trim()] = value.trim();
              }
            }
          }
        });
        if (!pseudo) {
          pseudo = 'default';
        }
        runtime[pseudo] = s;
      }
    }
  });
  return runtime;
}
function cssToStyle(css) {
  try {
    if (isString(css)) {
      return toNativeStyle(cssToRuntime(css)["default"]);
    }
    if (css["default"]) {
      return toNativeStyle(normalizeStyle(css["default"]));
    }
    return toNativeStyle(normalizeStyle(css));
  } catch (e) {
    // do nothing
  }
  return {};
}
export { hyphenate, camelize, toNativeStyle, normalizeStyle, toCss, cssToRuntime, cssToStyle };
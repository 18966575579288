import React, { PureComponent } from 'react';
import { Select, Button, Dialog, Input } from '@alifd/next';
declare var stores: any, toastr: any;
interface DigiforceFormItemSetterProps {
    value: string;
    placeholder: string;
    options: Array<{ label: string, value: string, [x: string]: any }> | Function;
    onChange: (val: string) => void;
    allowManageField: boolean;
    formId: string;
}

class DigiforceFormItemSetter extends PureComponent<DigiforceFormItemSetterProps, any> {
    static displayName = 'DigiforceFormItemSetter';
    static defaultProps = {
        placeholder: 'Please choose',
        queryObject: '',
        options: [{ label: '-', value: '' }],
        onChange: () => undefined as any,
    };

    valueProp: string;
    queryObject: string;
    queryFilters: Array<[string, string, string]>;
    queryFields: Array<string>;
    allowManageField = false;
    formId = '';
    options: Array<{ label: string, value: string, [x: string]: any }> | Function;

    constructor(props: DigiforceFormItemSetterProps) {
        super(props);
        this.valueProp = props.value;
        this.formId = props.formId;
        this.options = props.options || [];
        this.allowManageField = props.allowManageField;
    }

    state = {
        originForm: {},
        allowManageField: false,
        value: '',
        options: [],
        // Dialog
        visible: false,
        fieldName: '',
        fieldType: '',
        fields: [],
        selectedField: undefined,
        selectedFieldId: undefined,
        fieldTypes: [
            { label: 'Input', value: 'input' },
            { label: 'TextArea', value: 'textarea' },
        ]
    }

    async getFields() {
        const record = await stores.API.requestRecord('forms', this.formId, ['current']);

        const form = record?.current || {};
        const { fields = [] } = form;
        const options = fields.map((field: any) => {
            const { name: label, name: value, ...rest } = field;
            return {
                label,
                value,
                ...rest
            };
        });
        const formFields = fields.map((field: any) => {
            const { code } = field;
            return {
                ...field,
                code
            };
        });

        this.setState({
            originForm: record || {},
            options,
            fields: formFields,
            value: this.valueProp
        });
    }

    componentDidMount() {
        this.setState({
            options: this.options,
            allowManageField: this.allowManageField
        });

        this.getFields();

        console.log('XXX: componentDidMount');
    }

    componentDidUpdate() {

    }

    resetSelectedField = () => {
        this.setState({
            selectedField: undefined,
            selectedFieldId: undefined
        });
    };

    onFieldItemClick = (field: any) => {
        const id = (this.state.selectedField as any)?._id
        if (id === field._id) {
            this.resetSelectedField();
        } else {
            this.selectField(field);
        }
    }

    openDialog = () => {
        this.setState({
            visible: true
        });
    };

    closeDialog = () => {
        this.setState({
            visible: false,
            fieldName: '',
            fieldType: ''
        });
        this.resetSelectedField();
    };

    renderTitle = () => {
        return (
            <div className="variable-dialog-title">
                <span>Form Fields</span>
            </div>
        );
    };

    renderBottom = () => {
        return (
            <div className="variable-bind-dialog-bottom">
                <div className="bottom-left-container">
                    {this.state.selectedField && this.state.selectedFieldId && (
                        <Button type="normal" warning onClick={this.deleteField}>
                            Delete Field
                        </Button>
                    )}
                </div>

                <div className="bottom-right-container">
                    {/* <Button type="primary" onClick={this.onOk}>
                        OK
                    </Button> */}
                    {/* &nbsp;&nbsp; */}
                    <Button type="normal" onClick={this.closeDialog}>
                        Close
                    </Button>
                </div>
            </div>
        );
    };

    getFormData = (includeNew = true, id = '') => {
        const { selectedField, originForm, fields } = this.state;
        const form: any = originForm;

        let newFields = fields;
        if (id) {
            newFields = fields.filter((f: any) => { return f._id !== id; });
        }

        const data = _.cloneDeep({
            ...form,
            current: {
                ...form.current,
                fields: newFields
            }
        });

        if (includeNew) {
            const slField = selectedField as any;
            if (!slField._id) {
                data.current.fields.push({
                    ...slField,
                    _id: crypto.randomUUID()
                });
            } else {
                const index = data.current.fields.findIndex((f) => { return f._id === slField._id; });
                if (index !== -1) {
                    data.current.fields[index] = slField;
                }
            }
        }

        return data;
    }

    onSaveOrAdd = async () => {
        const { selectedField, fields } = this.state;
        const data = this.getFormData();

        const code = (selectedField as any).code;
        const existed = fields.find((field: any) => {
            return field.code === code;
        });

        if (existed) {
            toastr.error(`The field "${code}" is existed`)
        } else {
            await stores.API.updateRecord('forms', this.formId, data);
            await this.getFields();
            toastr.success(`Create field "${code}" successfully!`)
            this.resetSelectedField();
        }
    };

    deleteField = async () => {
        const { selectedField } = this.state;
        const code = (selectedField as any).code;
        const id = (selectedField as any)._id;
        const data = this.getFormData(false, id);
        await stores.API.updateRecord('forms', this.formId, data);
        await this.getFields();
        toastr.success(`Delete field "${code}" successfully!`)
        this.resetSelectedField();
    }

    addNewField = () => {
        const newField: any = {
            code: 'new_field',
            name: 'Display',
            type: 'input',
            is_list_display: false,
            is_multiselect: false,
            is_required: false,
            is_searchable: false,
            is_textarea: false,
            is_wide: false
        };

        this.selectField(newField);
    }

    selectField = (field: any) => {
        this.resetSelectedField();

        setTimeout(() => {
            this.setState({
                selectedField: field,
                selectedFieldId: field?._id || ''
            });
        }, 100);
    }

    onCodeChange = (val: any) => {
        this.setState({
            selectedField: {
                ...(this.state.selectedField as any),
                code: val
            }
        });
    };

    onNameChange = (val: any) => {
        this.setState({
            selectedField: {
                ...(this.state.selectedField as any),
                name: val
            }
        });
    };

    fieldInformationRender = () => {
        const self = this;
        if (!self.state.selectedField) {
            return null;
        }

        return (
            <div>
                <div className="lc-field lc-inline-field">
                    <div className="lc-field-head">
                        <div className="lc-field-title">
                            <span className="lc-title has-tip">
                                <span className="lc-title-txt">Name</span>
                            </span>
                        </div>
                    </div>
                    <div className="lc-field-body">
                        <div className="lc-setter-mixed">
                            <Input value={self.state.selectedField.code}
                                onChange={self.onCodeChange} />
                        </div>
                    </div>
                </div>
                <div className="lc-field lc-inline-field">
                    <div className="lc-field-head">
                        <div className="lc-field-title">
                            <span className="lc-title has-tip">
                                <span className="lc-title-txt">Display</span>
                            </span>
                        </div>
                    </div>
                    <div className="lc-field-body">
                        <div className="lc-setter-mixed">
                            <Input value={self.state.selectedField.name}
                                onChange={self.onNameChange} />
                        </div>
                    </div>
                </div>

                {/* <div className="lc-field lc-inline-field">
                    <div className="lc-field-head">
                        <div className="lc-field-title">
                            <span className="lc-title has-tip">
                                <span className="lc-title-txt">Type</span>
                            </span>
                        </div>
                    </div>
                    <div className="lc-field-body">
                        <div className="lc-setter-mixed">
                            <Select
                                placeholder="Select type"
                                autoWidth={false}
                                size="small"
                                value={self.state.selectedField.type}
                                dataSource={self.state.fieldTypes}
                                onChange={(val, actionType, item) => {
                                    self.setState({
                                        selectedField: {
                                            ...(self.state.selectedField as any),
                                            type: val,
                                            is_textarea: val === 'Textarea',
                                            code: val
                                        }
                                    });
                                }}
                                style={{ width: '100%' }}
                            />
                        </div>
                    </div>
                </div> */}

                <div className="lc-field lc-inline-field">
                    <div className="lc-field-head"></div>
                    <div className="lc-field-body">
                        <Button type="primary" onClick={self.onSaveOrAdd}>
                            {self.state.selectedFieldId ? 'Save' : 'Add'}
                        </Button>
                        &nbsp;&nbsp;
                        <Button type="normal" onClick={() => { self.selectField(undefined); }}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </div>
        )
    };

    render() {
        const { onChange, value, placeholder } = this.props;
        const {
            visible,
            fields,
            selectedFieldId
        } = this.state;

        const self = this;

        return (
            <div style={{ paddingTop: '15px' }}>
                <Select
                    placeholder={placeholder}
                    notFoundContent='No items'
                    autoWidth={false}
                    size="small"
                    value={this.state.value}
                    dataSource={this.state.options}
                    onChange={(val, actionType, item) => {
                        this.setState({
                            value: val
                        });
                        console.log('onChange: ', value, actionType, item);
                        onChange && onChange(val)
                    }}
                    style={{ width: '100%' }}
                />
                {this.state.allowManageField && (
                    <Button onClick={this.openDialog} style={{
                        border: 0,
                        padding: 0,
                        textDecoration: 'underline',
                        fontSize: '11px',
                        height: 'unset',
                        lineHeight: '0 !important',
                    }}>Manage Fields</Button>
                )}
                <Dialog
                    visible={visible}
                    title={self.renderTitle()}
                    onClose={self.closeDialog}
                    footer={self.renderBottom()}
                    popupContainer={undefined}
                    cancelProps={{ "aria-label": "cancel", "children": "Cancel" }}
                    okProps={{ "aria-label": "ok", "children": "OK" }}
                >
                    <div className="field-manage-dialog-body">
                        <div className="dialog-left-container">
                            <div className="dialog-small-title">Form Fields</div>
                            <div className="vs-field-manage-inner">
                                <ul className="vs-field-manage-selector-ul">
                                    {fields.map((field: any) => {
                                        return (
                                            <li
                                                onClick={() => self.onFieldItemClick(field)}
                                                className={selectedFieldId === field._id ? 'active' : ''}
                                                key={field}
                                            >
                                                <span title={field.name}>{field.name}</span>
                                            </li>
                                        );
                                    })}
                                    <li className="add-new-container">
                                        <Button type="normal" onClick={self.addNewField}>
                                            Add New Field
                                        </Button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="dialog-right-container">
                            <div className="dialog-small-title">Field Information</div>
                            <div className="vs-field-manage-inner">
                                {self.fieldInformationRender()}
                            </div>
                        </div>
                    </div>
                </Dialog>
            </div>
        );
    }
}

export default DigiforceFormItemSetter;
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Select, Balloon } from '@alifd/next';
import { project } from '@digiforce/dvd-engine';
import * as acorn from 'acorn';

import { isJSExpression, generateI18n } from './locale/utils';
import enUS from './locale/en-US';

import './index.less';

const { Option, AutoComplete } = Select;
const { Tooltip } = Balloon;
const helpMap = {
  this: 'Container context object',
  state: 'State of the container',
  props: 'Props of the container',
  context: 'Container context',
  schema: 'Page context object',
  component: 'Component context object',
  constants: 'Application constant object',
  utils: 'Utilities object',
  dataSourceMap: 'Container Data Source Map',
  field: 'Form field object',
};

export default class ExpressionView extends PureComponent {
  static displayName = 'Expression';

  static propTypes = {
    context: PropTypes.object,
    dataSource: PropTypes.array,
    locale: PropTypes.string,
    messages: PropTypes.object,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    value: PropTypes.string,
  };

  static defaultProps = {
    context: {},
    dataSource: [],
    locale: 'en-US',
    messages: enUS,
    onChange: () => {},
    placeholder: '',
    value: '',
  };

  expression: React.RefObject<unknown>;

  i18n: any;

  t: any;

  $input: any;

  listenerFun: ((event: any) => void) | undefined;

  static getInitValue(val: { value: any; match: (arg0: RegExp) => any }) {
    if (isJSExpression(val)) {
      if (typeof val === 'object') {
        return val.value;
      } else if (typeof val === 'string') {
        const arr = val.match(/^\{\{(.*?)\}\}$/);
        if (arr) return arr[1];
      }
    }
    return val;
  }

  constructor(props: any) {
    super(props);
    this.expression = React.createRef();
    this.i18n = generateI18n(props.locale, props.messages);
    this.state = {
      value: ExpressionView.getInitValue(props.value),
      dataSource: props.dataSource || [],
    };
  }

  static getDerivedStateFromProps(props: { value: any }, state: { preValue: any }) {
    const curValue = ExpressionView.getInitValue(props.value);
    if (curValue !== state.preValue) {
      return {
        preValue: curValue,
        value: curValue,
      };
    }
    return null;
  }

  onChange(value: string) {
    const realInputValue = value;
    const realDataSource = null;
    let nextCursorIndex: number;
    // Update
    // if (actionType === 'itemClick' || actionType === 'enter') {
    //   // const curValue = this.state.value;
    //   // if (curValue) {
    //   //   realInputValue = curValue + realInputValue;
    //   // }
    // }
    // Update data source
    const newState = {
      value: realInputValue,
    };
    if (realDataSource !== null) newState.dataSource = realDataSource;
    this.setState(newState, () => {
      nextCursorIndex && this.setInputCursorPosition(nextCursorIndex);
    });
    // Default plus variable expression
    this.t && clearTimeout(this.t);
    this.t = setTimeout(() => {
      const { onChange } = this.props;
      // realInputValue = realInputValue ? `{{${realInputValue}}}` : undefined;
      onChange &&
        onChange({
          type: 'JSExpression',
          value: realInputValue,
        });
    }, 300);
  }

  /**
   * Get AutoComplete data source
   * @param  {String}
   * @return {Array}
   */
  getDataSource(): any[] {
    const schema = project.exportSchema();
    const stateMap = schema.componentsTree[0].state;
    const dataSource = [];

    const datasourceMap = schema.componentsTree[0]?.dataSource;
    const list = datasourceMap?.list || [];

    for (const key in stateMap) {
      dataSource.push(`this.state.${key}`);
    }

    for (const item of list) {
      if (item && item.id) {
        dataSource.push(`this.state.${item.id}`);
      }
    }

    return dataSource;
  }

  /**
   * Object string before obtaining cursor, grammatical analysis obtaining object string
   * @param  {String} str Template string
   * @return {String}     The object string before the cursor
   */
  getCurrentFiled(str: string | any[]) {
    str += 'x'; // Add an x character after it to facilitate acorn parsing
    try {
      const astTree = acorn.parse(str);
      const right = astTree.body[0].expression.right || astTree.body[0].expression;
      if (right.type === 'MemberExpression') {
        const { start, end } = right;
        str = str.slice(start, end);
        return { str, start, end };
      }
    } catch (e) {
      return null;
    }
  }

  /**
   * Get the input context information
   * @param  {Array}
   * @return {Array}
   */
  getContextKeys(keys: []) {
    const { editor } = this.props.field;
    const limitKeys = ['schema', 'utils', 'constants'];
    if (keys.length === 0) return limitKeys;
    if (!limitKeys.includes(keys[0])) return [];
    let result = [];
    let keyValue = editor;
    let assert = false;
    keys.forEach((item) => {
      if (!keyValue[item] || typeof keyValue[item] !== 'object') {
        assert = true;
      }
      if (keyValue[item]) {
        keyValue = keyValue[item];
      }
    });
    if (assert) return [];
    result = Object.keys(keyValue);
    return result;
    // return utilsKeys.concat(constantsKeys).concat(schemaKeys);
  }

  /* Filter key */
  filterKey(obj: any, name: string) {
    const filterKeys = [
      'reloadDataSource',
      'REACT_HOT_LOADER_RENDERED_GENERATION',
      'refs',
      'updater',
      'appHelper',
      'isReactComponent',
      'forceUpdate',
      'setState',
      'isPureReactComponent',
    ];
    const result = [];
    for (const key in obj) {
      if (key.indexOf('_') !== 0 && filterKeys.indexOf(key) === -1) {
        result.push(`${name}.${key}`);
      }
    }
    return result;
  }

  /**
   * Filter according to the input item
   * @param  {String}
   * @param  {String}
   * @return {Boolen}
   */
  filterOption(inputValue: string, item: { value: string | any[] }) {
    const cursorIndex = this.getInputCursorPosition();
    const preStr = inputValue.substr(0, cursorIndex);
    const lastKey: string[] = preStr.split('.').slice(-1);
    if (!lastKey) return true;
    if (item.value.indexOf(lastKey) > -1) return true;
    return false;
  }

  // handleClick = () => {
  //   this.props.field.editor.emit('variableBindDialog.open');
  // }

  render() {
    const { value, dataSource } = this.state;
    const { placeholder } = this.props;
    const isValObject = !!(value == '[object Object]');
    const title = isValObject
      ? this.i18n('valueIllegal')
      : (value || placeholder || this.i18n('jsExpression')).toString();
    const cursorIndex = this.getInputCursorPosition();
    const childNode = cursorIndex ? (
      <div className="cursor-blink">
        {title.substr(0, cursorIndex)}
        <b>|</b>
        {title.substr(cursorIndex)}
      </div>
    ) : (
      title
    );

    return (
      <div ref={this.expression} style={{ width: '100%', display: 'inline-block' }}>
        <Tooltip
          triggerType={isValObject ? ['click'] : ['focus']}
          align="tl"
          popupClassName="code-input-overlay"
          trigger={
            isValObject ? (
              value
            ) : (
              <div>
                <AutoComplete
                  size="small"
                  {...this.props}
                  style={{ width: '100%' }}
                  dataSource={dataSource}
                  placeholder={placeholder || this.i18n('jsExpression')}
                  value={value}
                  disabled={isValObject}
                  innerBefore={<span style={{ color: '#999', marginLeft: 4 }}>{'{{'}</span>}
                  innerAfter={<span style={{ color: '#999', marginRight: 4 }}>{'}}'}</span>}
                  popupClassName="expression-setter-item-inner"
                  // eslint-disable-next-line no-shadow
                  itemRender={(itemValue) => {
                    return (
                      <Option key={itemValue.value} text={itemValue.label} value={itemValue.value}>
                        <div className="code-input-value">{itemValue.value}</div>
                        <div className="code-input-help">{helpMap[itemValue.value]}</div>
                      </Option>
                    );
                  }}
                  onChange={this.onChange.bind(this)}
                  filter={this.filterOption.bind(this)}
                />
              </div>
            )
          }
        >
          {childNode}
        </Tooltip>
      </div>
    );
  }

  componentDidMount() {
    this.$input = this.findInputElement();
    if (this.$input) {
      this.listenerFun = (event) => {
        const isMoveKey = !!(event.type == 'keyup' && ~[37, 38, 39, 91].indexOf(event.keyCode));
        const isMouseup = event.type == 'mouseup';
        if (isMoveKey || isMouseup) {
          // eslint-disable-next-line react/no-access-state-in-setstate
          const dataSource = this.getDataSource(this.state.value) || [];
          this.setState({
            dataSource,
          });
        }
      };
      this.$input.addEventListener('keyup', this.listenerFun, false);
      this.$input.addEventListener('mouseup', this.listenerFun, false);
    }
  }

  componentWillUnmount() {
    if (this.listenerFun && this.$input) {
      this.$input.removeEventListener('keyup', this.listenerFun, false);
      this.$input.removeEventListener('mouseup', this.listenerFun, false);
    }
  }

  /**
   * Get the input input box DOM node
   */
  findInputElement() {
    return this.expression.current.children[0].getElementsByTagName('input')[0];
  }

  /**
   * Get the cursor position
   *
   */
  getInputCursorPosition() {
    if (!this.$input) return;
    return this.$input.selectionStart;
  }

  /*
   * String to get object Keys
   */
  getObjectKeys(str: string) {
    let keys: string | any[] = [];
    if (str) keys = str.split('.');
    return keys.slice(0, keys.length - 1);
  }

  /*
   * Set the cursor position of the input component before the closing }
   */
  setInputCursorPosition(idx: number) {
    this.$input.setSelectionRange(idx, idx);
    this.forceUpdate();
  }
}

export var AssetLevel;
(function (AssetLevel) {
  AssetLevel[AssetLevel["Environment"] = 1] = "Environment";
  AssetLevel[AssetLevel["Library"] = 2] = "Library";
  AssetLevel[AssetLevel["Theme"] = 3] = "Theme";
  AssetLevel[AssetLevel["Runtime"] = 4] = "Runtime";
  AssetLevel[AssetLevel["Components"] = 5] = "Components";
  AssetLevel[AssetLevel["App"] = 6] = "App";
})(AssetLevel || (AssetLevel = {}));
export var AssetLevels = [AssetLevel.Environment, AssetLevel.Library, AssetLevel.Theme, AssetLevel.Runtime, AssetLevel.Components, AssetLevel.App];
export var AssetType;
(function (AssetType) {
  AssetType["JSUrl"] = "jsUrl";
  AssetType["CSSUrl"] = "cssUrl";
  AssetType["CSSText"] = "cssText";
  AssetType["JSText"] = "jsText";
  AssetType["Bundle"] = "bundle";
})(AssetType || (AssetType = {}));
import React, { PureComponent } from 'react';
import { Select } from '@alifd/next';
declare var stores: any;

interface DigiforceSelectObjectSetterProps {
  value: string;
  valueField: string;
  labelField: string;
  defaultValue: string;
  placeholder: string;
  queryObject: string | Function;
  onChange: (val: string) => void;
  queryFilters: Array<[string,string,string]>;
  queryFields: Array<string>
  options: Array<{ label: string, value: string, [x: string]: any}> | Function;
  [x: string]: any;
}

class DigiforceSelectObjectSetter extends PureComponent<DigiforceSelectObjectSetterProps, any> {
  static displayName = 'DigiforceSelectObjectSetter'
  static defaultProps = {
    placeholder: 'Please choose',
    queryObject: '',
    options: [{ label: '-', value: '' }],
    defaultValue: null as any,
    onChange: () => undefined as any,
    filters: []
  };
  valueField = '_id'
  labelField = 'label'
  returnField: string
  queryObject: string
  queryFilters: Array<[string,string,string]>;
  queryFields: Array<string>;
  options: Array<{ label: string, value: string, [x: string]: any}> | Function;
  valueProp: string | any;
  constructor(props: DigiforceSelectObjectSetterProps) {
    super(props);
    this.valueField   = props.valueField || this.valueField;
    this.labelField   = props.labelField || this.labelField;
    this.queryObject  = typeof props.queryObject == 'function' ? props.queryObject(props.prop) : props.queryObject;
    this.queryFilters = props.queryFilters
    this.options = typeof props.options == 'function' ? props.options(props.prop) : props.options;
    this.valueProp = this.props.value
  }

  componentDidMount() {
    this.query(this.valueProp)
  }

  query(resetValue: string | any) {
    let queryFields = (this.queryFields || [])
    queryFields.push(this.valueField, this.labelField)
    if (this.returnField) queryFields.push(this.returnField)
    stores.API.client.graphql.query(this.queryObject, this.queryFilters, queryFields, {})
      .then(({data: { records }}: { data: { records: []}}) => {
        console.log('this.props.value', this.props.value)
        this.setState({
          dataSource: records?.map((record): any => {
            const { [this.valueField]: value, [this.labelField]: label, ...rest }: any = record;
            return ({ label, value, ...rest });
          }) || [],
          value: this.valueProp
        })
      })
      .catch(console.error)
  }

  componentDidUpdate(prevProps: Readonly<DigiforceSelectObjectSetterProps>, prevState: Readonly<any>, snapshot?: any): void {
    if (
      (this.queryObject)
      !=
      (typeof prevProps.queryObject == 'function' && prevProps.queryObject(prevProps.prop) || prevProps.queryObject)
    ) {
      this.queryObject = typeof prevProps.queryObject == 'function' && prevProps.queryObject(prevProps.prop) || prevProps.queryObject
      console.log('componentDidUpdate')
      this.query(null)
    }
  }

  state = {
    setterValue: null,
    dataSource: [],
    value: null
  };

  render() {
    const { onChange, mode, value, placeholder, reloadFieldsOnChange, prop } = this.props;
    const self = this;
    return (
      <Select
        hasClear
        placeholder={placeholder}
        notFoundContent='No items'
        autoWidth={false}
        size="small"
        value={this.state.value}
        dataSource={this.state.dataSource}
        mode={mode}
        onChange={(val, actionType, item) => {
          console.log(prop)
          this.setState({
            value: val
          })
          const returnVal = !self.returnField ? val : item?.[self.returnField]
          reloadFieldsOnChange?.map((field: string) => {
            prop.setPropValue(field, null)
          })

          onChange && onChange(returnVal)
        }}
        style={{ width: '100%' }}
      />
    );
  }
}

export default DigiforceSelectObjectSetter;
(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@alifd/next")["Icon"]);
	else if(typeof define === 'function' && define.amd)
		define([["@alifd/next","Icon"]], factory);
	else if(typeof exports === 'object')
		exports["___AliLowCodeEngineExt___"] = factory(require("@alifd/next")["Icon"]);
	else
		root["___AliLowCodeEngineExt___"] = factory(root["Next"]["Icon"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__61__) {
return 